<template>
  <date-range-picker
            ref="picker"
            opens="left"
            :ranges="customRanges()"
            control-container-class="btn btn-white"
            :append-to-body="true"
            :locale-data="{ firstDay: 1, format: 'mm-dd-yyyy' }"
            v-model="range"
            @update="updateValues"
            :maxDate="today">
            <template v-slot:input="picker">
              <Button btnVariant="yellow" class="ButtonHover">
                <i class="pr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar-range"
                    viewBox="0 0 16 16">
                    <path
                      d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1M1 9h4a1 1 0 0 1 0 2H1z" />
                    <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                  </svg>
                </i>
                <span v-if="picker.startDate"
                  >{{ customDateFormat(picker.startDate) }} -
                  {{ customDateFormat(picker.endDate) }}</span
                >
                <span v-else class="">Select Date</span>
                <i class="fa-solid pl-2 fa-chevron-down"></i>
              </Button>
              <b class="caret"></b>
            </template>
            <template #ranges="ranges">
              <div class="ranges custom-ranges">
                <ul>
                  <li
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                    @click="ranges.clickRange(range)">
                    <b>{{ name }}</b>
                  </li>
                </ul>
              </div>
            </template>
            <template #footer="data">
              <div class="ranges custom-ranges">
                <div class="d-flex justify-content-end align-items-center">
                  <small class="text-muted px-2">{{
                    data.rangeText.split("-").length == 2
                      ? "Select Date"
                      : data.rangeText
                  }}</small>
                  <b-button
                    variant="link p-0"
                    style="font-size: 13px; color: #4d1b7e"
                    @click="data.clickCancel"
                    >Cancel</b-button
                  >
                  <Button @click="data.clickApply" style="transform: scale(0.7)"
                    >Apply</Button
                  >
                </div>
              </div>
            </template>
          </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import Button from './Button.vue'
export default {
    components:{
        Button,
        DateRangePicker
    },
    data(){
        return {
             today: new Date(),
              range: {
        startDate: null,
        endDate: null,
      },
        }
    },
    methods:{
         customDateFormat(date, isUpdateFormate = false) {
      const inputDate = new Date(date);

      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = inputDate.getFullYear();

      const formattedDate = isUpdateFormate
        ? `${year}-${month}-${day}`
        : `${day}-${month}-${year}`;
      return formattedDate;
    },
          updateValues(dates) {
      const startDate = dates.startDate
        ? this.customDateFormat(dates.startDate, true)
        : "";
      const endDate = dates.endDate
        ? this.customDateFormat(dates.endDate, true)
        : "";
        this.$emit('updateValues',{startDate:startDate,endDate:endDate})
    },
          customRanges() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1
      );
      const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      const thisMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);

      const thisWeekStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay()
      );
      const thisWeekEnd = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay() + 6
      );

      // Uncomment and use these if you need 'Last Week' range
      // const lastWeekStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7);
      // const lastWeekEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 1);

      const last15DaysStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 14
      );
      const last15DaysEnd = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "This Month": [thisMonthStart, thisMonthEnd],
        "Last Month": [lastMonthStart, lastMonthEnd],
        "This Week": [thisWeekStart, thisWeekEnd],
        // 'Last Week': [lastWeekStart, lastWeekEnd],
        "Last 15 Days": [last15DaysStart, last15DaysEnd],
        Clear: [null, null], // 'Clear' option to clear the selection
      };
    },
    }
}
</script>

<style>

</style>